.blue-team {
    color: #2625c3;
}

.red-team {
    color: #c52523;
}

.green-team {
    color: #26c623;
}

.orange-team {
    color: #c58b23;
}

.yellow-team {
    color: #b7c523;
}

.purple-team {
    color: #8923c3;
}

.aqua-team {
    color: #4a7bb4;
}

.pink-team {
    color: #c53878;
}
